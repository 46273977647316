<script>
  import {
    keywords_store,
    startup_state,
    animation_finished,
    random_button_initiated,
    loading_keywords,
    is_tag_array
  } from "$lib/store.js";
  import { _ } from "svelte-i18n";
  import Keywords from "$lib/Keywords.svelte";
  import { PUBLIC_API_BASE_URL } from "$env/static/public";
  import { goto } from "$app/navigation";
  import { getTitles } from "$lib/search.js";
  import { onMount } from "svelte";
  import Bubble from "./Bubble.svelte";
  import KeywordInspiration from "$lib/KeywordInspiration.svelte";

  export let cache;
  export let samplePrompt;

  $animation_finished = false;
  let prompt = "";
  let index = 0;
  let intervalId;
  let initialRandomButton;

  let searchDiv;
  let searchElements;
  let searchComponent;
  let keywordsDiv;
  let keywordInspirations;
  let searchFieldContainer;
  let windowWidth;
  let searchElementsRect;
  let claim;

  $: margins = windowWidth < 480 ? 22 : 44;

  const typeChar = () => {
    if (index < samplePrompt.length && !$animation_finished) {
      prompt += samplePrompt[index];
      index += 1;
    } else {
      setTimeout(() => {
        $animation_finished = true;
        prompt = "";
        index = 999;
        clearInterval(intervalId);
      }, 1000); // 1 second delay
    }
  };

  function startTypeAnimation() {
    clearKeywords();
    samplePrompt = $_(`sample_prompt${Math.trunc(Math.random() * 70) + 1}`);
    index = 0;
    $animation_finished = false;
    clearInterval(intervalId);
    intervalId = setInterval(typeChar, 20);
  }
  startTypeAnimation();

  $: if ($animation_finished) {
    getKeywords(samplePrompt);
  }

  $: if ($keywords_store.length > 0) {
    getTitles();
  }

  let validationError = "";
  const maxTotalLength = 256;
  const maxWordLength = 42;

  function validate(question) {
    const totalLength = question.replace(/\s+/g, "").length;
    if (totalLength > maxTotalLength) {
      validationError = $_("error_description_too_long");
      return false;
    }

    // forbid strange characters
    const validSentence = /^[\p{L}\d\s.,;:'"!?><&%$+=@*/-]+$/u;
    if (!validSentence.test(question)) {
      validationError = $_("error_not_a_valid_sentence");
      return false;
    }

    const wordsArray = question.split(/\s+/);
    if (wordsArray.some((word) => word.length > maxWordLength)) {
      validationError = $_("error_words_cannot_be_too_long");
      return false;
    }

    // forbid words that only contain punctuation
    const realWords = /^[^\p{L}\d]+$/u;
    const isSinglePunktuation = /^[,:;./'"><%$&*+=-]$/;
    if (wordsArray.some((word) => realWords.test(word) && !isSinglePunktuation.test(word))) {
      validationError = $_("error_words_need_letters");
      return false;
    }

    validationError = "";
    return true;
  }

  function sanitizePrompt(question) {
    question = question.normalize();
    question = question.trim();
    question = question.replace(/\s+([.,;:!?])/g, "$1");
    question = question.replace(/\s+/g, " ");
    return question;
  }

  $is_tag_array = false;
  function fadeKeywords() {
    if (!$is_tag_array && !$random_button_initiated) {
      setTimeout(() => {
        $is_tag_array = true;
        initialRandomButton = true;
        localStorage.setItem("random_button_initiated", JSON.stringify(true));
      }, "2000");
    }
  }

  async function getKeywords(question) {
    $loading_keywords = true;
    let keywords = cache[question];
    if (!keywords) {
      keywords = await getKeywordsFromAPI(question);
    }
    updateKeywords(keywords);
    $loading_keywords = false;

    prompt = "";
    index = 999;
  }

  async function getKeywordsFromAPI(question) {
    question = sanitizePrompt(question);
    if (question != "" && validate(question)) {
      const endpoint = "/api/search/getTagsFromPrompt";
      const url = `${PUBLIC_API_BASE_URL}/${endpoint}/${encodeURIComponent(question)}`;
      let response_json;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch keywords");
        }
        response_json = await response.json();
        return response_json.data.tags;
      } catch (err) {
        console.error(err.message);
        goto("/error");
      }
    }
  }

  function updateKeywords(new_keywords) {
    keywords_store.update((keywords) => {
      // Map guarantees unique entries and acts as a set: (JS has no comaparator for Set)
      const keywordsMap = new Map(keywords.map((keyword) => [keyword.displayText, keyword]));
      // add new keywords, overwrite existing ones:
      new_keywords.forEach((k) => {
        keywordsMap.set(k.displayText, k);
      });
      return Array.from(keywordsMap.values());
    });
    fadeKeywords();
  }

  function clearKeywords() {
    keywords_store.update(() => {
      return [];
    });
  }

  function keypressed(e) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (e.key === "Enter") {
      getKeywords(prompt);
    }
  }

  function clicked() {
    // workaround for iPhone scrolling up when keyboard slides in
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300); // 300ms delay to let the keyboard fully open

    keywordInspirations.style.height = `${keywordInspirations.scrollHeight - claim.scrollHeight}px`;

    clearInterval(intervalId);
    prompt = "";
    index = 999;
    if ($startup_state) {
      clearKeywords();
    }
    changeStartupState(false);
    initialRandomButton = false;
    updateSearchComponentHeight();
  }

  function changeStartupState(b) {
    if ($startup_state) {
      startup_state.update(() => {
        return b;
      });
    }
  }

  function getRandomPrompt() {
    initialRandomButton = false;
    validationError = "";
    window._paq.push(["trackEvent", `random search triggered`]);
    clicked();
    startTypeAnimation();
  }

  const makeSticky = () => {
    searchElementsRect = searchElements.getBoundingClientRect();
    if (
      searchElementsRect.bottom + searchElementsRect.height / 2 <= window.innerHeight &&
      searchElementsRect.height + searchElementsRect.height / 2 <= window.innerHeight
    ) {
      searchDiv.style.position = "sticky";
      searchDiv.style.top = "var(--header-height)";
    } else {
      searchDiv.style.position = "relative";
      searchDiv.style.top = "auto";
    }
  };

  const updateSearchComponentHeight = () => {
    const currentHeight = searchElements.offsetHeight;
    searchComponent.style.height = currentHeight + "px";
    searchElementsRect = searchElements.getBoundingClientRect();
    makeSticky();
  };

  const checkScroll = () => {
    searchElementsRect = searchElements.getBoundingClientRect();

    if (scrollY > 0 && searchElementsRect.bottom + searchElementsRect.height / 2 <= window.innerHeight) {
      searchElements.style.height = `${searchFieldContainer.clientHeight + margins}px`;
      searchComponent.style.height = `${searchFieldContainer.clientHeight + margins}px`;
      keywordsDiv.style.marginTop = `18px`;
    } else {
      searchElements.style.height = `${searchFieldContainer.scrollHeight + keywordsDiv.scrollHeight + keywordInspirations.scrollHeight + 28}px`;
      keywordsDiv.style.marginTop = `0px`;
      setTimeout(() => {
        searchElements.style.height = `auto`;
      }, 250);
    }
  };

  const handleResize = () => (windowWidth = window.innerWidth);

  onMount(() => {
    clearKeywords();
    changeStartupState(true);
    // smooth transition of height of streamer component
    searchDiv = document.querySelector("#search");
    searchElements = document.querySelector("#search-elements");
    searchComponent = document.querySelector("#search-component");
    keywordsDiv = document.querySelector("#keywords");
    keywordInspirations = document.querySelector("#keyword-inspirations");
    searchFieldContainer = document.querySelector("#search-field-container");
    claim = document.querySelector("#claim");
    searchElementsRect = searchElements.getBoundingClientRect();
    updateSearchComponentHeight();
    handleResize();

    const observer = new MutationObserver(updateSearchComponentHeight);
    observer.observe(searchElements, { attributes: true, childList: true, subtree: true, attributeFilter: ["style"] });

    searchElements.addEventListener("transitionend", updateSearchComponentHeight);
    window.addEventListener("scroll", checkScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      observer.disconnect();
      window.removeEventListener("scroll", checkScroll);
      window.removeEventListener("resize", handleResize);
    };
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div id="search-component">
  <div id="search-wrap">
    <div id="search-elements">
      <KeywordInspiration />
      <div id="search-field-container">
        <div class="search-field" id="bubbleAnchor">
          <input
            class="search-input"
            bind:value={prompt}
            placeholder={$_("search-prompt")}
            on:keyup|preventDefault={(event) => {
              if (event.key === "Enter") {
                // Trigger on "Enter" only
                keypressed(event);
                event.target.blur(); // Remove focus (close keyboard on mobile)
              }
            }}
            on:click|preventDefault={clicked}
            style="padding-right: {prompt == '' ? '0px' : '62px'}" />
          <span
            role="button"
            tabindex="0"
            title={$_("add-search-term")}
            on:click|preventDefault={() => getKeywords(prompt)}
            on:keypress|preventDefault={() => getKeywords(prompt)}>
            <img
              class="keyword-button"
              alt={$_("add-search-term")}
              style="visibility: {prompt == '' ? 'hidden' : 'visible'}" />
          </span>
          <span
            role="button"
            style="display: inline-block; width:60px; height:60px"
            tabindex="0"
            title="random search"
            on:click|preventDefault={() => getRandomPrompt()}
            on:keypress|preventDefault={() => getRandomPrompt()}>
            <img
              class={initialRandomButton ? "random-search-button initial" : "random-search-button"}
              alt={$_("add-search-term")}
              style="visibility: {prompt == '' ? 'visible' : 'hidden'}" />
          </span>
        </div>
      </div>
      {#if initialRandomButton}
        <Bubble
          orientation="top right"
          --fill-color={"var(--mowizz-logo-blue)"}
          --place-self={"end"}
          anchorSelector="#bubbleAnchor">
          <p>{$_("random_keywords")}</p>
        </Bubble>
      {/if}
      {#if validationError}
        <div class="error">{validationError}</div>
      {/if}
      <Keywords />
      <!-- <Keywords {isTagArray} {loadingKeywords} /> -->
    </div>
  </div>
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .error {
    margin-bottom: 5px;
    color: var(--bright-red);
  }
  #search-component {
    position: sticky;
    top: var(--header-height);
    border-radius: 36px;
    background-image: radial-gradient(
        circle at 82px -100px,
        rgb(20, 137, 205) 12px,
        rgba(4, 76, 118, 1) 48px,
        rgba(4, 76, 118, 0.1) 180px,
        rgba(4, 76, 118, 0) 240px
      ),
      linear-gradient(
        110deg,
        rgb(23, 37, 56) 180px,
        rgb(19, 29, 46) 73%,
        /*rgba(113, 32, 79, 1) 90%  ,*/ rgb(43, 21, 46) 100%
      );
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    width: 94%;
    transition: height 0.25s ease-in-out;
    margin-bottom: 4px;
  }
  #search-wrap {
    display: flex;
    width: 100%;
  }
  #search-elements {
    align-items: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  #search-field-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 22px;
    margin-bottom: 8px;
  }
  .search-field {
    background: url("/icons/icon_search.svg") no-repeat scroll 0px 0px;
    border: 1px solid transparent;
    border-radius: 12px;
    height: 60px;
    position: relative;
    width: 45em;
    max-width: 90%;
    overflow: hidden;
  }
  .search-field:hover {
    border: 1px solid var(--white);
    cursor: pointer;
  }
  .search-input {
    background: rgba(181, 229, 255, 0.2);
    border: 1px solid var(--black);
    border-radius: 12px;
    box-sizing: border-box;
    color: var(--white);
    font-size: 18px;
    height: 100%;
    padding-left: 3.4em;
    width: 100%;
  }
  .keyword-button {
    content: url("/buttons/btn_add_keywords.svg");
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .keyword-button:hover {
    content: url("/buttons/btn_add_keywords_hover.svg");
    cursor: pointer;
  }
  .random-search-button {
    content: url("/buttons/btn_random_search.svg");
    width: 60px;
    height: 60px;
    margin: 0;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .random-search-button.initial {
    content: url("/buttons/btn_random_search_initial.svg");
  }
  .random-search-button:hover {
    content: url("/buttons/btn_random_search_hover.svg");
    cursor: pointer;
  }
  ::placeholder {
    color: var(--white-50);
    opacity: 1;
  }
  @media (max-width: 480px) {
    #search-component {
      width: 100%;
      border-radius: 0px;
    }
    .search-field {
      background: url(/icons/icon_search.svg) no-repeat scroll -10px 0px;
      max-width: 96%;
    }
    #search-field-container {
      margin-top: 12px;
    }
    .search-input {
      padding-left: 2.5em;
    }
  }
</style>
